import { assert, json } from "fetchkit";
import { navigate, PageProps } from "gatsby";
import * as React from "react";

function useInput(defaultValue = "") {
  const [value, setValue] = React.useState(defaultValue);
  const onChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    []
  );

  return { value, onChange };
}

export default function SigninPage({ }: PageProps) {
  const phoneInput = useInput();
  const codeInput = useInput();

  const handleButtonClick = React.useCallback(async () => {
    try {
      const response = await fetch(
        `/api/send-code`,
        json({
          phone: phoneInput.value,
        })
      );
      const data = await response.json();
      if (data.message) {
        throw Error(data.message);
      }
    } catch (e) {
      alert((e as Error).message);
    }
  }, [phoneInput.value]);
  const handleSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        const response = await fetch(
          `/api/signin`,
          json({
            phone: phoneInput.value,
            code: codeInput.value,
          })
        );
        const data = await response.json();
        if (data.message) {
          throw Error(data.message);
        }
        location.href = "/";
      } catch (e) {
        alert((e as Error).message);
      }
    },
    [phoneInput.value, codeInput.value]
  );
  return (
    // <div style={{ width: "100vw", height: "100vh" }}>
    <form method="post" onSubmit={handleSubmit} >
      <h1>登录乐享艺术</h1>
      <div>
        <input
          type="tel"
          name="phone"
          placeholder="请输入乐享艺术手机号"
          {...phoneInput}
        />
        <button type="button" onClick={handleButtonClick}>
          获取验证码
        </button>
      </div>
      <br />
      <div>
        <input
          type="tel"
          name="code"
          placeholder="请输入验证码"
          {...codeInput}
        />
        <button type="submit" >{`登录`}</button>
      </div>
    </form >
    // </div>

  );
}
